.TopBarOuter{
    display:block;
    position:relative;
    height: 44pt;
    z-index: 10;
  }


.TopBar{
    display: flex;
    flex-direction: row;
    height: 44pt;
    background-color: #272A27bb;
    backdrop-filter: blur(7px);
    justify-content: space-between;
    align-items: center;
    padding-left: 24pt;
    padding-right: 24pt;
    width: calc(100vw - 48pt);
    z-index: 10;
    position:relative;

    top: 0;
    position:fixed;
    z-index: 10;
}

@font-face {
    font-family: Modernist;
    src: url(../assets/SkModernist-Regular.otf);
}

.TopBarItem{
    font-size: 11pt;
    font-family: Modernist;
    color: #F3DAD0;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
}

.TopBarItemIcon{
    max-width:27pt;
    max-height:27pt;
    position:relative;
    z-index: 10;
}

.DropDownOuter{
    float:top;
    position: absolute;
    top: 0pt;
    background-color: #272A27bb;
    z-index: -50;
}

.DropDownOuter::before{
    content: '';
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    
    -webkit-backdrop-filter: blur(7pt);
    backdrop-filter: blur(7pt);
    z-index: -50;
}

.DropDownOuterMiddle{
    left: calc(50vw - 72pt);
    width: 144pt;
}

.DropDownOuterRight{
    right: 0pt;
    width: 96pt;
    text-align: right;
    padding-right: 18pt;
}

.DropDownColumn{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 44pt;
}

.DropdownMenuRow {
    padding: 12pt;
    text-decoration: none;
    color: #F3DAD0;
    z-index: 10;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    width:100%;
    height:100%;
}