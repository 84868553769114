:root {
    --rowHeight: calc(100vh - 112pt) ;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F3F3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


hmtl{
  text-rendering: optimizeLegibility;
  /* text-rendering: geometricPrecision; */
  shape-rendering: geometricPrecision;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  
  /* -webkit-text-stroke: 1px  #06213833; */

  -webkit-font-feature-settings: "kern", normal;

          font-feature-settings: "kern", normal;

  font-kerning: normal;
  font-size-adjust: 0.618;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  /* image-rendering:optimizeQuality; */
/* 
  -moz-osx-font-smoothing: auto;
  font-smoothing:auto;
  font-smooth: auto;
  -webkit-font-smoothing: auto; */
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
.App {
  text-align: center;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F9F3F3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16pt);
  color: #184351;
}

.Error{
  background-color: #C9D8C1;
  font-family: Modernist-Bold;
  font-size: calc(16pt);
  color: #184351;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  margin-top: 12pt;
  padding: 12pt;
}

.App-link {
  color: #237BBD;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.TopBarOuter{
    display:block;
    position:relative;
    height: 44pt;
    z-index: 10;
  }


.TopBar{
    display: flex;
    flex-direction: row;
    height: 44pt;
    background-color: #272A27bb;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    justify-content: space-between;
    align-items: center;
    padding-left: 24pt;
    padding-right: 24pt;
    width: calc(100vw - 48pt);
    z-index: 10;
    position:relative;

    top: 0;
    position:fixed;
    z-index: 10;
}

@font-face {
    font-family: Modernist;
    src: url(/static/media/SkModernist-Regular.5da9e3e4.otf);
}

.TopBarItem{
    font-size: 11pt;
    font-family: Modernist;
    color: #F3DAD0;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
}

.TopBarItemIcon{
    max-width:27pt;
    max-height:27pt;
    position:relative;
    z-index: 10;
}

.DropDownOuter{
    float:top;
    position: absolute;
    top: 0pt;
    background-color: #272A27bb;
    z-index: -50;
}

.DropDownOuter::before{
    content: '';
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    
    -webkit-backdrop-filter: blur(7pt);
    backdrop-filter: blur(7pt);
    z-index: -50;
}

.DropDownOuterMiddle{
    left: calc(50vw - 72pt);
    width: 144pt;
}

.DropDownOuterRight{
    right: 0pt;
    width: 96pt;
    text-align: right;
    padding-right: 18pt;
}

.DropDownColumn{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 44pt;
}

.DropdownMenuRow {
    padding: 12pt;
    text-decoration: none;
    color: #F3DAD0;
    z-index: 10;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    width:100%;
    height:100%;
}
.Page{
    display: block;
    position:relative;
    background-color: #C9D8C1;
    justify-content: space-between;
    align-items: top;
    padding: 66pt;
    margin-top: 12pt;
    padding-bottom: 6pt;
}

@font-face {
    font-family: Modernist;
    src: url(/static/media/SkModernist-Regular.5da9e3e4.otf);
}

@font-face {
    font-family: Modernist-Bold;
    src: url(/static/media/SkModernist-Bold.071a03b6.otf);
}

@font-face {
    font-family: Vision;
    src: url(/static/media/Vision.4220a439.otf);
}

.PageBottomStatement{
    font-size: 10pt;
    font-family: Modernist;
    color: #184351;
    text-decoration: none;
    margin-top: 96pt;
}    

.PageContentColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 24pt;
}

.PageTitle{
    font-size: 28pt;
    font-family: Modernist-Bold;
    color: #184351;
    text-decoration: none;
    text-align: left;
    margin-bottom: 20pt;
}

.PageRowText{
    font-size: 11pt;
    font-family: Modernist;
    color: #184351;
    text-decoration: none;
    margin-top: 12pt;
    margin-bottom: 12pt;
    text-align: left;
}

.FormSend{
    margin-bottom: 12pt;
    text-align: left;
}

.GenericInput{
    display: flex;
    flex-direction: row;
    align-items:center;
    width:100%;
    justify-content: space-between;

}

.GenericInputLabel{
    font-size: 17pt;
    font-family: Modernist-Bold;
    padding-right: 24pt;
    color: #184351;

}

.InputBox{
    text-align: left;
    padding-left: 12pt;
    padding-right: 12pt;
    padding-top: 4pt;
    padding-bottom: 4pt;
    height: 100%;
    min-height: 33pt;
    margin-top: 8pt;
    margin-bottom: 8pt;
    font-size: 12pt;
    font-family: Vision;
    border: unset;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #B0BBD3;
    background-color: #F9F3F3;
    color: #08268B;

}

.GenericInputBox{
    width: 62%;
}

.SpecialInputBox{
    width: calc(100% - 24pt);
}


.FormButton{
    width: 38%;
    align-self:flex-end;
    height: 100%;
    min-height: 33pt;
    margin-top: 8pt;
    margin-bottom: 8pt;

    font-size: 11pt;
    font-family: Modernist;
    background-color: #c1d5d8;
    color: #184351;
    text-decoration: none;

    border: unset;
    border-style: solid;
    border-width: 1px;
    border-radius: 11px;
    border-color: #237BBD;
}

.FormButtonSubmitting{
    background-color: #184351 !important;
    color: #C9D8C1 !important;
    font-family: Modernist-Bold !important;
    font-size: 13pt !important;
    padding-bottom: 4pt !important;
}

.SectionTitle{
    font-size: 18pt;
    font-family: Modernist-Bold;
    color: #184351;
    text-decoration: none;
    text-align: left;
    margin-top: 20pt;
}

.PageRowIcon{
    width:132pt;
    align-content: flex-end;
}

.LargeBox{
    min-height: 308pt;
    padding-top: 12pt;
}


input::-webkit-input-placeholder {
    color: #B0BBD3;
}


input:-ms-input-placeholder {
    color: #B0BBD3;
}


input::placeholder {
    color: #B0BBD3;
}
textarea::-webkit-input-placeholder {
    color: #B0BBD3;
}
textarea:-ms-input-placeholder {
    color: #B0BBD3;
}
textarea::placeholder {
    color: #B0BBD3;
}
input:focus{
    color: #08268B;
}
textarea:focus {
    color: #08268B;
}

@media screen and (max-width: 707px){
    .Page{
        padding: 0pt;
    }
    .PageContentColumn{
        margin: 12pt;
    }
    .PageTitle{
        font-size: 23pt;
        margin: 12pt;
        padding-top: 18pt;
    }
    .PageRowIcon{
        width:112pt;
    }
    .PageBottomStatement{
        margin: 0;
        margin-top: 48pt;
        padding-bottom: 8pt;
    }    

}
.BottomBar{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;
    align-items: top;
    z-index: 888;
    padding: 66pt;
}

.BottomBarColumn{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: left;
    z-index: 888;
}

.BottomBarOuter{
    display: block;
    position:relative;
    bottom: 0;
    background-color: #272A27bb;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    margin-top: 12pt;
}

.BottomBarStatement{
    max-width: 100%;
    align-items: top;
    z-index: 888;
    font-size: 10pt;
    font-family: Modernist;
    color: #F3DAD0;
    padding-top: 8pt;
    padding-bottom: 8pt;
}


@font-face {
    font-family: Modernist;
    src: url(/static/media/SkModernist-Regular.5da9e3e4.otf);
}

.BottomBarLink{
    font-size: 11pt;
    font-family: Modernist;
    color: #F3DAD0;
    text-decoration: none;
    padding-top: 8pt;
    padding-bottom: 8pt;
}

.BottomBarLinkIcon{
    max-width:44pt;
    max-height:44pt;
    width:100%;
    margin-top: 8pt;
    margin-bottom: 8pt;
}

@media screen and (max-width: 707px){
    .BottomBar{
        padding-right: 12pt;
        padding-left: 12pt;
        padding-bottom: 12pt;
    }
}
:root {
    --rowHeight: calc(100vh - 112pt);
    --imgSize: 264pt;
}

.HBSOuter{
    padding: 44pt;
    margin-top: 12pt;
    height: calc(100vh - 112pt);
    height: var(--rowHeight);
    box-sizing: border-box;
}

.HBSbckgrndImg{
    position:absolute;
    left: 0;
    top: 0;
    width:100%;
    height:100%;
    z-index: 1;
}

.HBSOuterFull{
    display: block;
    width: 100%;
    position: relative;
}

.HBSOuterLeft{
    display: inline-block;
    width: calc(50% - 6pt);
    left: 0;
    margin-right: 6pt;
    vertical-align:top;
}

.HBSOuterRight{
    display: inline-block;
    width: calc(50% - 6pt);
    right: 0;
    margin-left: 6pt;
    vertical-align:top;
}

.HBSContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 62%;
    max-height: 87%;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 5;
    position: relative;
}

@font-face {
    font-family: Modernist;
    src: url(/static/media/SkModernist-Regular.5da9e3e4.otf);
}

.HBSContentRowLink{
    font-size: 11pt;
    font-family: Modernist;
    color: #F3DAD0;
    text-decoration: none;
    padding-top: 4pt;
}

.HBSContentRowImg{
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    text-align: right;
    justify-content:flex-end;
}

.HBSContentRowImgRight{
    width:96pt;
}

.HBSContentImgLarge{
    width: 264pt;
    width: var(--imgSize);
}

.TwoColumnRow{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.TwoColumnItem{
    height: 100%;
    width: 50%;
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.HBSContentRowTitle{
    font-size: 16pt;
    font-family: Modernist-Bold;
    color: #0C2128;
    text-decoration: none;
    text-align: left;
    padding-top: 6pt;
    padding-bottom: 3pt;
}

.HBSContentTitle{
    font-size: 23pt;
    font-family: Modernist-Bold;
    color: #0C2128;
    text-decoration: none;
    text-align: left;
    padding-bottom: 8pt;
    z-index: 5;
    position: relative;
}

.HBSContentRowText{
    font-size: 13pt;
    font-family: Modernist;
    color: #0C2128;
    text-decoration: none;
    text-align: left;
    padding-top: 4pt;
}

.HBSContentRowTextSmall{
    font-size: 11pt !important;
    padding-top: 2pt !important;
}

@media screen and (max-width: 707px){
    .HBSOuter{
        padding: 18pt;
    }
}
