.App {
  text-align: center;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F9F3F3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16pt);
  color: #184351;
}

.Error{
  background-color: #C9D8C1;
  font-family: Modernist-Bold;
  font-size: calc(16pt);
  color: #184351;
  filter: blur(1px);
  margin-top: 12pt;
  padding: 12pt;
}

.App-link {
  color: #237BBD;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
