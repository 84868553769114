.BottomBar{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;
    align-items: top;
    z-index: 888;
    padding: 66pt;
}

.BottomBarColumn{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: left;
    z-index: 888;
}

.BottomBarOuter{
    display: block;
    position:relative;
    bottom: 0;
    background-color: #272A27bb;
    backdrop-filter: blur(7px);
    margin-top: 12pt;
}

.BottomBarStatement{
    max-width: 100%;
    align-items: top;
    z-index: 888;
    font-size: 10pt;
    font-family: Modernist;
    color: #F3DAD0;
    padding-top: 8pt;
    padding-bottom: 8pt;
}


@font-face {
    font-family: Modernist;
    src: url(../assets/SkModernist-Regular.otf);
}

.BottomBarLink{
    font-size: 11pt;
    font-family: Modernist;
    color: #F3DAD0;
    text-decoration: none;
    padding-top: 8pt;
    padding-bottom: 8pt;
}

.BottomBarLinkIcon{
    max-width:44pt;
    max-height:44pt;
    width:100%;
    margin-top: 8pt;
    margin-bottom: 8pt;
}

@media screen and (max-width: 707px){
    .BottomBar{
        padding-right: 12pt;
        padding-left: 12pt;
        padding-bottom: 12pt;
    }
}