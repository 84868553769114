:root {
    --rowHeight: calc(100vh - 112pt);
    --imgSize: 264pt;
}

.HBSOuter{
    padding: 44pt;
    margin-top: 12pt;
    height: var(--rowHeight);
    box-sizing: border-box;
}

.HBSbckgrndImg{
    position:absolute;
    left: 0;
    top: 0;
    width:100%;
    height:100%;
    z-index: 1;
}

.HBSOuterFull{
    display: block;
    width: 100%;
    position: relative;
}

.HBSOuterLeft{
    display: inline-block;
    width: calc(50% - 6pt);
    left: 0;
    margin-right: 6pt;
    vertical-align:top;
}

.HBSOuterRight{
    display: inline-block;
    width: calc(50% - 6pt);
    right: 0;
    margin-left: 6pt;
    vertical-align:top;
}

.HBSContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 62%;
    max-height: 87%;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 5;
    position: relative;
}

@font-face {
    font-family: Modernist;
    src: url(../assets/SkModernist-Regular.otf);
}

.HBSContentRowLink{
    font-size: 11pt;
    font-family: Modernist;
    color: #F3DAD0;
    text-decoration: none;
    padding-top: 4pt;
}

.HBSContentRowImg{
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    text-align: right;
    justify-content:flex-end;
}

.HBSContentRowImgRight{
    width:96pt;
}

.HBSContentImgLarge{
    width: var(--imgSize);
}

.TwoColumnRow{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.TwoColumnItem{
    height: 100%;
    width: 50%;
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.HBSContentRowTitle{
    font-size: 16pt;
    font-family: Modernist-Bold;
    color: #0C2128;
    text-decoration: none;
    text-align: left;
    padding-top: 6pt;
    padding-bottom: 3pt;
}

.HBSContentTitle{
    font-size: 23pt;
    font-family: Modernist-Bold;
    color: #0C2128;
    text-decoration: none;
    text-align: left;
    padding-bottom: 8pt;
    z-index: 5;
    position: relative;
}

.HBSContentRowText{
    font-size: 13pt;
    font-family: Modernist;
    color: #0C2128;
    text-decoration: none;
    text-align: left;
    padding-top: 4pt;
}

.HBSContentRowTextSmall{
    font-size: 11pt !important;
    padding-top: 2pt !important;
}

@media screen and (max-width: 707px){
    .HBSOuter{
        padding: 18pt;
    }
}