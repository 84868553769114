.Page{
    display: block;
    position:relative;
    background-color: #C9D8C1;
    justify-content: space-between;
    align-items: top;
    padding: 66pt;
    margin-top: 12pt;
    padding-bottom: 6pt;
}

@font-face {
    font-family: Modernist;
    src: url(../assets/SkModernist-Regular.otf);
}

@font-face {
    font-family: Modernist-Bold;
    src: url(../assets/SkModernist-Bold.otf);
}

@font-face {
    font-family: Vision;
    src: url(../assets/Vision.otf);
}

.PageBottomStatement{
    font-size: 10pt;
    font-family: Modernist;
    color: #184351;
    text-decoration: none;
    margin-top: 96pt;
}    

.PageContentColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 24pt;
}

.PageTitle{
    font-size: 28pt;
    font-family: Modernist-Bold;
    color: #184351;
    text-decoration: none;
    text-align: left;
    margin-bottom: 20pt;
}

.PageRowText{
    font-size: 11pt;
    font-family: Modernist;
    color: #184351;
    text-decoration: none;
    margin-top: 12pt;
    margin-bottom: 12pt;
    text-align: left;
}

.FormSend{
    margin-bottom: 12pt;
    text-align: left;
}

.GenericInput{
    display: flex;
    flex-direction: row;
    align-items:center;
    width:100%;
    justify-content: space-between;

}

.GenericInputLabel{
    font-size: 17pt;
    font-family: Modernist-Bold;
    padding-right: 24pt;
    color: #184351;

}

.InputBox{
    text-align: left;
    padding-left: 12pt;
    padding-right: 12pt;
    padding-top: 4pt;
    padding-bottom: 4pt;
    height: 100%;
    min-height: 33pt;
    margin-top: 8pt;
    margin-bottom: 8pt;
    font-size: 12pt;
    font-family: Vision;
    border: unset;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #B0BBD3;
    background-color: #F9F3F3;
    color: #08268B;

}

.GenericInputBox{
    width: 62%;
}

.SpecialInputBox{
    width: calc(100% - 24pt);
}


.FormButton{
    width: 38%;
    align-self:flex-end;
    height: 100%;
    min-height: 33pt;
    margin-top: 8pt;
    margin-bottom: 8pt;

    font-size: 11pt;
    font-family: Modernist;
    background-color: #c1d5d8;
    color: #184351;
    text-decoration: none;

    border: unset;
    border-style: solid;
    border-width: 1px;
    border-radius: 11px;
    border-color: #237BBD;
}

.FormButtonSubmitting{
    background-color: #184351 !important;
    color: #C9D8C1 !important;
    font-family: Modernist-Bold !important;
    font-size: 13pt !important;
    padding-bottom: 4pt !important;
}

.SectionTitle{
    font-size: 18pt;
    font-family: Modernist-Bold;
    color: #184351;
    text-decoration: none;
    text-align: left;
    margin-top: 20pt;
}

.PageRowIcon{
    width:132pt;
    align-content: flex-end;
}

.LargeBox{
    min-height: 308pt;
    padding-top: 12pt;
}


input::placeholder {
    color: #B0BBD3;
}
textarea::placeholder {
    color: #B0BBD3;
}
input:focus{
    color: #08268B;
}
textarea:focus {
    color: #08268B;
}

@media screen and (max-width: 707px){
    .Page{
        padding: 0pt;
    }
    .PageContentColumn{
        margin: 12pt;
    }
    .PageTitle{
        font-size: 23pt;
        margin: 12pt;
        padding-top: 18pt;
    }
    .PageRowIcon{
        width:112pt;
    }
    .PageBottomStatement{
        margin: 0;
        margin-top: 48pt;
        padding-bottom: 8pt;
    }    

}