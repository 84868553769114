:root {
    --rowHeight: calc(100vh - 112pt) ;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F3F3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


hmtl{
  text-rendering: optimizeLegibility;
  /* text-rendering: geometricPrecision; */
  shape-rendering: geometricPrecision;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  
  /* -webkit-text-stroke: 1px  #06213833; */

  font-kerning: normal;
  font-size-adjust: 0.618;
  font-variant-ligatures: normal;
  /* image-rendering:optimizeQuality; */
/* 
  -moz-osx-font-smoothing: auto;
  font-smoothing:auto;
  font-smooth: auto;
  -webkit-font-smoothing: auto; */
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}